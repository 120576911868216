.header {
  height: 400px;
  padding: 2em 0;
  text-align: center;
  text-transform: uppercase;
  box-shadow: none;
}

.header .title {
  color: #eee;
  display: inline-block;
}

.header .title .bold {
  font-size: 5em;
  font-weight: bolder;
  text-shadow: -2px -2px #fcda02, 4px 4px #e83d84;
  line-height: 1.3em;
  color: #fff;
  display: flex;
  align-items: center;
}

.header .title .bold img {
  margin: 0 0.3em;
  height: 1.3em;
}

.header .title .thin {
  color: #ffbbe0;
  font-size: initial;
  font-weight: lighter;
  display: block;
}

.header .title .left {
  text-align: left;
  margin-bottom: -10px;
}

.header .title .right {
  text-align: right;
  margin-top: -10px;
}

.flex {
  display: flex;
}

.content {
  position: relative;
  background: #fff;
  color: #666;
  max-width: 1240px;
  margin: 10px auto;
  padding: 40px 50px;
  min-height: 0;
  top: -220px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
  z-index: 21;
}

.content.hero {
  background: #fff6f0;
  background-image: url('./kth.jpg'), linear-gradient(135deg, #fbeff3 0%,#fff6f0 100%);
  background-size: cover;
  background-position: center;
  display:flex;
  flex-direction: row;
}


.content.hero .more-btn {
  display: inline-block;
  padding: 6px 15px;
  background: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: lighter;
  margin-top: 20px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .1);
  color: #828ca2;
}

.content.hero .more-btn:hover,
.content.hero .more-btn:focus {
  transform: scale(1.05);
  box-shadow: 0 3px 3px rgba(0, 0, 0, .1);
}

.content.hero h4.empty {
  display: block;
  text-align: center;
  color: #828ca2;
  font-size: 15px;
  padding: 30px 0;
}

.content .intro {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 33%;
}

.content .intro h3 {
  font-weight: lighter;
  margin: 0;
  padding: 0;
  color: #64708d;
  line-height: 165%;
  text-align: center;
  font-size: 1.1em;
}

.content .intro p {
  display: none;
}

.content .news {
  width: 33%;
}

.content .news h2 {
  color: #e83d84;
  font-size: 2.75em;
  font-weight: 900;
  text-shadow: 1px 1px #fff;
  margin: 20px;
  text-transform: uppercase;
  transition: color 0.1s ease-out;
}

.content .news h2:hover,
.content .news h2:focus {
    color: #ec5f99;
}

.content .news ul {
  list-style: none;
  padding: 0;
}

.content .news ul li {
  margin: 10px;
  margin-top: 22px;
}

.content .news ul li h3 {
  border: none;
  padding: 0;
  margin: 10px;
  margin-bottom: 1px;
  font-size: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #6a758f;
  transition: color 0.1s ease-out;
}

.content .news ul li h3:hover,
.content .news ul li h3:focus {
  color: #4a5164;
}

.content .news ul div {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #959eb3;
}

.content .news ul li span {
  margin: 0 10px;
  text-transform: uppercase;
  font-weight: lighter;
  font-size: 13px;
  color: #959eb3;
  white-space: nowrap;
}

.content:after {
  content: '';
  display: block;
  clear: both;
}

@media only screen and (max-width: 1250px) {
  .header .title .bold {
    font-size: 4em;
  }
}

@media only screen and (max-width: 1000px) {
  .header .title .bold {
    flex-direction: column;
  }

  .content.hero {
    flex-direction: column;
    align-items: center;
  }

  .content {
    top: -105px;
  }

  .content .intro {
    width: 75%;
  }

  .content .news {
    width: 95%;
  }
}

@media only screen and (max-width: 545px) {
  .content {
    top: -190px;
    padding-left: 0;
    padding-right: 0;
  }

  .header .title .bold {
    font-size: 2.5em;
    text-shadow: -1px -1px #fcda02, 2px 2px #e83d84;
  }

  .content .news h2 {
    font-size: 2em;
  }
}
