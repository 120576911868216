body {
  margin: 0;
  padding: 0;
  font-family: Lato, sans-serif;
}

h3, .cerise #content h3 {
  border: none;
}

.pagination .disabled, .pagination .disabled a {
  color: #999 !important;
}

#secondary-nav a {
  display: flex !important;
  align-items: center;
}

.page-icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  margin: 0 0.5em;
  padding: 0;
  border-radius: 50%;
}
